import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const PersonalGrowthPage = () => {
  return (
    <Layout title="Personal Growth After Dinner Parties">
        <h2>Scenario</h2>
        <blockquote>I have just returned from a dinner-party of outgoing strangers [...] need to cleanse myself [...] the ghosts they have left behind in my body.</blockquote>
        <center><Image src="personalgrowth.png" alt="Digital ink portrait of a woman from the torso up. She has a surreal growth emerging from her back, neck, and part of her eye. She is dressed in a strapless party dress, has her hair cut to a very short pixies or grown out buzz cut, and has a piercing in each nostril." /></center>
        <center><Image src="personalgrowth_crop1.png" alt="Detail cut out of the above drawing, showing just the woman's face and the part of the growth emerging from her eye." /></center>
        <center><Image src="personalgrowth_crop2.png" alt="Detail cut out of the above drawing, showing some of the growth. The growth has some muscular and skeletal features and in two places looks reminiscent of a hand dripping excess matter." /></center>
    </Layout>
  )
}

export default PersonalGrowthPage